<template>
  <a-card class="card" :bordered="false">
    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-row>
        <a-col span="6">
          <a-form-model-item label="公司代码">
            <a-input v-model="form.company_code" />
          </a-form-model-item>
        </a-col>
        <a-col span="6">
          <a-form-model-item label="类型">
            <a-select v-model="form.type" allow-clear>
              <a-select-option v-for="(item, index) in options" :key="index" :value="item.value">
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row style="margin:15px 0;">
        <a-button @click="searchInfo" class="ant-btn-query margin-right10">查询</a-button>
        <a-button type="primary" @click="newRecord" class="margin-right10">新增</a-button>
        <a-button type="danger" :disabled="!selectedRowKeys.length" class="margin-right10" @click="delRecord('all')">删除</a-button>
        <a-upload
          :customRequest="handleCustomerImport"
          :showUploadList="false"
        >
          <a-button type="primary">导入客户</a-button>
        </a-upload>
      </a-row>
    </a-form-model>
    <a-table
      rowKey="id"
      :scroll="{ x: true }"
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      @change="changeTable"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
    >
      <div slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </div>
      <div slot="action" slot-scope="text, record">
        <a @click="modifyRecord(record)" class="margin-right10">修改</a>
        <a @click="delRecord('row', record)">删除</a>
      </div>
    </a-table>
    <a-modal v-model="visible" :title="modelTitle" @ok="handleSave" @cancel="handleCancel">
      <a-form-model
        ref="ruleForm"
        :model="formModel"
        :rules="rules"
        :label-col="labelModel"
        :wrapper-col="wrapperModel"
      >
        <a-form-model-item label="类型" prop="type">
          <a-select v-model="formModel.type">
            <a-select-option v-for="(item, index) in options" :key="index" :value="item.value">
              {{ item.name }}
            </a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="公司代码" prop="company_code">
          <a-input v-model="formModel.company_code" />
        </a-form-model-item>
        <a-form-model-item label="客户SAP编码" prop="sap_code">
          <a-input v-model="formModel.sap_code" />
        </a-form-model-item>
        <a-form-model-item label="关联方属性" prop="link_attr">
          <a-input v-model="formModel.link_attr" />
        </a-form-model-item>
        <a-form-model-item label="客户中文名称" prop="customer_cn_name">
          <a-input v-model="formModel.customer_cn_name" />
        </a-form-model-item>
        <a-form-model-item label="客户英文名称" prop="customer_en_name">
          <a-input v-model="formModel.customer_en_name" />
        </a-form-model-item>
        <a-form-model-item label="客户账户组" prop="account_group">
          <a-input v-model="formModel.account_group" />
        </a-form-model-item>
        <a-form-model-item label="快速搜索项" prop="search_key">
          <a-input v-model="formModel.search_key" />
        </a-form-model-item>
        <a-form-model-item label="街道" prop="street">
          <a-input v-model="formModel.street" />
        </a-form-model-item>
        <a-form-model-item label="邮政编码" prop="post_code">
          <a-input v-model="formModel.post_code" />
        </a-form-model-item>
        <a-form-model-item label="城市" prop="city">
          <a-input v-model="formModel.city" />
        </a-form-model-item>
        <a-form-model-item label="国家" prop="country">
          <a-input v-model="formModel.country" />
        </a-form-model-item>
        <a-form-model-item label="语言" prop="language">
          <a-input v-model="formModel.language" />
        </a-form-model-item>
        <a-form-model-item label="总部账号" prop="head_account">
          <a-input v-model="formModel.head_account" />
        </a-form-model-item>
        <a-form-model-item label="贸易伙伴" prop="trade_partner">
          <a-input v-model="formModel.trade_partner" />
        </a-form-model-item>
        <a-form-model-item label="现金管理组" prop="cash_group">
          <a-input v-model="formModel.cash_group" />
        </a-form-model-item>
        <a-form-model-item label="统驭科目" prop="control_subject">
          <a-input v-model="formModel.control_subject" />
        </a-form-model-item>
        <a-form-model-item label="排序码" prop="sort_code">
          <a-input v-model="formModel.sort_code" />
        </a-form-model-item>
        <a-form-model-item label="支付条件" prop="pay_condition">
          <a-input v-model="formModel.pay_condition" />
        </a-form-model-item>
        <a-form-model-item label="原系统客户编码" prop="origin_customer_code">
          <a-input v-model="formModel.origin_customer_code" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-card>
</template>
<script>
import { getCommonPage, createCommonInfo, updateCommonInfo, deleteCommonInfo, customerImport } from '@/api/common'
export default {
  data() {
    return {
      labelCol: { span: 4 },
      labelModel: { span: 8 },
      wrapperModel: { span: 12 },
      wrapperCol: { span: 14 },
      form: {
        company_code: '',
        type: 0
      },
      formModel: {
        type: 0,
        company_code: '',
        sap_code: '',
        link_attr: '',
        customer_cn_name: '',
        customer_en_name: '',
        account_group: '',
        search_key: '',
        street: '',
        post_code: '',
        city: '',
        country: '',
        language: '',
        head_account: '',
        trade_partner: '',
        cash_group: '',
        control_subject: '',
        sort_code: '',
        pay_condition: '',
        origin_customer_code: ''
      },
      rules: {
        type: [{ required: true, message: '请选择类型', trigger: 'blur' }]
        // company_code: [{ required: true, message: '请输入公司代码', trigger: 'blur' }],
        // sap_code: [{ required: true, message: '请输入客户SAP编码', trigger: 'blur' }],
        // link_attr: [{ required: true, message: '请输入关联方属性', trigger: 'blur' }],
        // customer_cn_name: [{ required: true, message: '请输入客户中文名称', trigger: 'blur' }],
        // customer_en_name: [{ required: true, message: '请输入客户英文名称', trigger: 'blur' }],
        // account_group: [{ required: true, message: '请输入客户账户组', trigger: 'blur' }],
        // search_key: [{ required: true, message: '请输入快速搜索项', trigger: 'blur' }],
        // street: [{ required: true, message: '请输入街道', trigger: 'blur' }],
        // post_code: [{ required: true, message: '请输入邮政编码', trigger: 'blur' }],
        // city: [{ required: true, message: '请输入城市', trigger: 'blur' }],
        // country: [{ required: true, message: '请输入国家', trigger: 'blur' }],
        // language: [{ required: true, message: '请输入语言', trigger: 'blur' }],
        // head_account: [{ required: true, message: '请输入总部账号', trigger: 'blur' }],
        // trade_partner: [{ required: true, message: '请输入贸易伙伴', trigger: 'blur' }],
        // cash_group: [{ required: true, message: '请输入现金管理组', trigger: 'blur' }],
        // control_subject: [{ required: true, message: '请输入统驭科目', trigger: 'blur' }],
        // sort_code: [{ required: true, message: '请输入排序码', trigger: 'blur' }],
        // pay_condition: [{ required: true, message: '请输入支付条件', trigger: 'blur' }],
        // origin_customer_code: [{ required: true, message: '请输入原系统客户编码', trigger: 'blur' }]
      },
      options: [
        { name: '集团内客户', value: 0 },
        { name: '集团外关联客户', value: 1 },
        { name: '非经营性客户', value: 2 }
      ],
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '类型',
          dataIndex: 'type',
          customRender: text => {
            if (text === 0) {
              return '集团内客户'
            } else if (text === 1) {
              return '集团外关联客户'
            } else if (text === 2) {
              return '非经营性客户'
            }
          }
        },
        {
          title: '公司代码',
          dataIndex: 'company_code'
        },
        {
          title: '客户SAP编码',
          dataIndex: 'sap_code'
        },
        {
          title: '关联方属性',
          dataIndex: 'link_attr'
        },
        {
          title: '客户中文名称',
          dataIndex: 'customer_cn_name'
        },
        {
          title: '客户英文名称',
          dataIndex: 'customer_en_name'
        },
        {
          title: '客户账户组',
          dataIndex: 'account_group'
        },
        {
          title: '快速搜索项',
          dataIndex: 'search_key'
        },
        {
          title: '街道',
          dataIndex: 'street'
        },
        {
          title: '邮政编码',
          dataIndex: 'post_code'
        },
        {
          title: '城市',
          dataIndex: 'city'
        },
        {
          title: '国家',
          dataIndex: 'country'
        },
        {
          title: '语言',
          dataIndex: 'language'
        },
        {
          title: '总部账号',
          dataIndex: 'head_account'
        },
        {
          title: '贸易伙伴',
          dataIndex: 'trade_partner'
        },
        {
          title: '现金管理组',
          dataIndex: 'cash_group'
        },
        {
          title: '统驭科目',
          dataIndex: 'control_subject'
        },
        {
          title: '排序码',
          dataIndex: 'sort_code'
        },
        {
          title: '支付条件',
          dataIndex: 'pay_condition'
        },
        {
          title: '原系统客户编码',
          dataIndex: 'origin_customer_code'
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      data: [],
      selectedRowKeys: [],
      visible: false,
      modelTitle: '新增SAP客户信息',
      modelKey: 'a',
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      },
      moduleName: 'sys_customer'
    }
  },
  created() {
    this.searchInfo()
  },
  methods: {
    getCommonPage,
    createCommonInfo,
    updateCommonInfo,
    deleteCommonInfo,
    getInfo(param) {
      getCommonPage(this.moduleName, {
        page: this.pagination.current,
        page_size: this.pagination.pageSize,
        ...param
      }).then(res => {
        this.data = res.list
        this.pagination.total = res.total
      })
    },
    searchInfo() {
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.getInfo(Object.assign({}, this.form))
    },
    changeTable(pagination) {
      this.pagination = pagination
      this.getInfo(Object.assign({}, this.form))
    },
    newRecord() {
      this.modelTitle = '新增SAP客户信息'
      this.modelKey = 'a'
      this.visible = true
      this.formModel = {
        type: 0,
        company_code: '',
        sap_code: '',
        link_attr: '',
        customer_cn_name: '',
        customer_en_name: '',
        account_group: '',
        search_key: '',
        street: '',
        post_code: '',
        city: '',
        country: '',
        language: '',
        head_account: '',
        trade_partner: '',
        cash_group: '',
        control_subject: '',
        sort_code: '',
        pay_condition: '',
        origin_customer_code: ''
      }
    },
    delRecord(flag, record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          let _ids = []
          if (flag === 'all') {
            _ids = this.selectedRowKeys
          } else {
            _ids = [record.id]
          }
          deleteCommonInfo(this.moduleName, { ids: _ids }).then(res => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.searchInfo()
          })
        }
      })
    },
    modifyRecord(record) {
      this.modelTitle = '修改SAP客户信息'
      this.modelKey = null
      this.visible = true
      this.formModel = Object.assign({}, record)
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    handleSave() {
      if (this.modelKey === 'a') {
        // 新增
        createCommonInfo(this.moduleName, this.formModel).then(res => {
          if (res) {
            this.$message.success('新增成功!')
            const param = Object.assign({}, this.form)
            param.page = this.pagination.current
            param.pageSize = this.pagination.pageSize
            this.getInfo(param)
          } else {
            this.$message.error('新增失败!')
          }
        })
      } else {
        // 修改
        updateCommonInfo(this.moduleName, this.formModel).then(res => {
          if (res) {
            this.$message.success('修改成功!')
            const param = Object.assign({}, this.form)
            param.page = this.pagination.current
            param.pageSize = this.pagination.pageSize
            this.getInfo(param)
          } else {
            this.$message.error('修改失败!')
          }
        })
      }
      this.visible = false
    },
    handleCancel(e) {
      this.$refs.ruleForm.resetFields()
      this.visible = false
    },
    handleCustomerImport(obj) {
      var formData = new FormData();
      formData.append('uploadFile', obj.file);
      customerImport(formData).then(v => {
        obj.onSuccess()
        this.$notification['success']({
          message: '提示',
          description: '导入成功'
        })
        this.searchInfo()
      })
    }
  }
}
</script>
<style scoped>
.ant-table td {
  white-space: nowrap;
}
.margin-right10 {
  margin-right: 10px;
}
.ant-form-item {
  margin-bottom: 4px;
}
</style>
